import {useIntl} from "gatsby-plugin-intl"
import * as React from "react"
import {Provider} from "react-redux"
import {DomainRedirect} from "../../components/DomainRedirect"
import {globalStore} from "../../components/Index"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {LocationContext} from "../../routes"

// TODO: remove this page once EWM is online
const PlaceHolderPage: React.FC = () => {
  const intl = useIntl()
  return (
  <div style={{ height: "72vh", textAlign: "center", paddingTop: "40px" }}>
    {intl.locale === "de" ?
      "Willkommen auf der EWM-Extreme Weather Monitoring|Monitoring Wetter- und Klimaextreme Unterseite. Diese Daten werden im Frühjahr 2024 online gestellt." :
      "Welcome to the EWM-Extreme Weather Monitoring subpage. These data will go online by spring 2024."
    }
  </div>
  )
}

const R: React.FC = ({ location }) => {
  return (
    <LocationContext.Provider value={location}>
    <DomainRedirect />
    <Provider store={globalStore}>
      <Layout>
        <SEO title="GCCI" />{" "}
        <PlaceHolderPage />
      </Layout>
    </Provider>
  </LocationContext.Provider>
  )
}
export default R
